// // INITIATE MARGINS AND PADDING

@mixin property($property, $values) {
  $top: $property + "-top";
  $bottom: $property + "-bottom";
  $right: $property + "-right";
  $left: $property + "-left";

  @if length($values) == 1 {
    #{$top}: calc(#{nth($values, 1)} * var(--base-space));
    #{$bottom}: calc(#{nth($values, 1)} * var(--base-space));
    #{$right}: calc(#{nth($values, 1)} * var(--base-space));
    #{$left}: calc(#{nth($values, 1)} * var(--base-space));
  } @else if length($values) == 2 {
    #{$top}: calc(#{nth($values, 1)} * var(--base-space));
    #{$bottom}: calc(#{nth($values, 1)} * var(--base-space));
    #{$right}: calc(#{nth($values, 2)} * var(--base-space));
    #{$left}: calc(#{nth($values, 2)} * var(--base-space));
  } @else if length($values) == 3 {
    #{$top}: calc(#{nth($values, 1)} * var(--base-space));
    #{$bottom}: calc(#{nth($values, 3)} * var(--base-space));
    #{$right}: calc(#{nth($values, 2)} * var(--base-space));
    #{$left}: calc(#{nth($values, 2)} * var(--base-space));
  } @else if length($values) == 4 {
    #{$top}: calc(#{nth($values, 1)} * var(--base-space));
    #{$bottom}: calc(#{nth($values, 3)} * var(--base-space));
    #{$right}: calc(#{nth($values, 2)} * var(--base-space));
    #{$left}: calc(#{nth($values, 4)} * var(--base-space));
  }
}

@mixin padding($values) {
  @include property(padding, $values);
}

@mixin margin($values) {
  @include property(margin, $values);
}
