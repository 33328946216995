.block-type-collapse{
  border-bottom: 1px solid;
  &:first-child{
    margin-top: 0;
  }
  &:last-of-type{
    margin-bottom: 2em;
  }
}

.collapsible {
  position: relative;
  color: inherit;
  cursor: pointer;
  background: transparent;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  @include padding(0.4 0);
}

/* Style the collapsible content. Note: hidden by default */
.content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
  &.collapsed{
    @include mq(md){
      max-height: 30vh !important;
    }
    max-height: unset;
  }
}
.collapsible + .content{
  overflow: scroll;
}
.content-inner{
  @include padding(0.4 0 0.4 0);
  columns: 1;
  @include mq(md) {
    &[data-columns="one"] {
      columns: 1;
    }
    &[data-columns="two"] {
      columns: 2;
      column-fill: balance;
      padding-bottom: 0.4em;
      page-break-inside: avoid;
      break-inside: avoid;
      -webkit-column-break-inside: avoid;
    }
  }
  p{
    margin-bottom: 1em;
    &:last-of-type{
      margin-bottom: 0.2em;
    }
  }
  a{
    @include font(mainita);
    display: inline-block;
    margin-left: 0.5em;
  }
  a.underline{
    @include font(main);
    margin-left: 0em;
  }
  a.hover{
    @include font(main);
    margin-left: 0;
    &:hover{
      @include font(mainita);
    }
  }
  a + br + a.underline{
    text-indent: 0 !important;
  }
  a + a.underline{
    text-indent: 0 !important;
  }

  h1{
    display: block;
    font-size: inherit;
    font-weight: normal;
  }

}


.table{
  max-width: 100%;
  @include mq(md){
    max-width: 50%;
  }
  & + p{
    margin-top: 1em;
  }
  .row{
    display: grid;
    grid-template-areas: "a a";
    grid-auto-columns: 1fr 4em;
    @include mq(md){
      grid-auto-columns: 80% 4em;
    }
    p{
      margin-bottom: 0;
    }
  }
  a{
    @include font(main);
    @extend .underline-md;
  }
}

.collapsible:after {
  @include font(icons);
  content:"\f106";
  float: right;
  margin-left: 5px;
  position: absolute;
  height: 100%;
  right: 0;
  top:-0.1em;
  display: flex;
  align-items: center;

}

.collapsible.active:after {
  content: "\f105"; /* Unicode character for "minus" sign (-) */
  height: 100%;
  vertical-align: baseline;
}