#home main.blurred{
  @include mq(md){
    filter: unset;
  }
}
.current-slide{
  height: 50%;
  overflow: hidden;
  @include mq(md){
    height: 100%;
  }
}

.home-slide{
  .gray{
    filter: grayscale(1);
  }
  a{
    pointer-events: auto;
    @include mq(md){
    pointer-events: none;
    }
  }

}

.salon-slide{
  height: 50%;
  width: 100%;
  position: absolute;
  top:0;
  left:0;
  z-index: 9;
  opacity: 0;
  transition: opacity $transition-duration-default*2 $transition-duration-default;
  pointer-events: none;
  &.show{
    opacity: 1;
    pointer-events: auto;
    transition: opacity $transition-duration-default*2 $transition-duration-default;
  }
  @include mq(md){
    height: 100%;
  }
}

.color-filter{
  position: fixed;
  top: 100%;
  left: 0;
  height: 100%;
  width: 100%;
  pointer-events: none;
  z-index: 999;
  transition: top $transition-duration-default $transition-timing-default;

  &.in-view{
    top: 0;
    transition: top $transition-duration-default $transition-timing-default;
  }
}


#multiply{
  background: #8664ff;
  mix-blend-mode: multiply;
  transform: translate3d(0,0,0);
  pointer-events: none;
}

#grayscale{
  pointer-events: none;
  display: none;
  /*background: black;
  transform: translate3d(0,0,0);
  mix-blend-mode: color;
  pointer-events: none;*/
}

.scroll-down{
  display: none;
  position: fixed;
  z-index: 99999;
  bottom: 0.9em;
  right: -1.5em;
  width: min-content;
  cursor: pointer;
  &:before{
    font-size: 7em;
  }
  &.revert{
    transform: rotate(180deg);
    bottom: -1em;
  }
  @include mq(md){
    display: block;
  }
}

.scroll-down.hide{
  display: none;
}