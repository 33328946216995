.icon:before {
  @include font(icons);
  @include fs(icons);
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-burger:before {
  content: "\f101";
}
.icon-cross:before {
  content: "\f102";
}
.icon-flechebas:before {
  content: "\f103";
}
.icon-flechehaut:before {
  content: "\f104";
}
.icon-moins:before {
  content: "\f105";
}
.icon-plus:before {
  content: "\f106";
}
.icon-zdoublearrow-01:before {
  content: "\f107";
}
.icon-zdoublearrow:before {
  content: "\f107";
}
