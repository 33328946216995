// DESKTOP MENU

.menu {
  display: none;
  background: $purple;
  position: fixed;
  top: var(--hh);
  width: 100%;
  .secondary-menu{
    display: none;
  }
  &.menu-active{
    display: flex;
    padding-left: 3.5em;
  }
  z-index: 99;
  @include padding(0.4 0.65 0.4 0.65);
  @include mq(md) {
    top: unset;
    z-index: unset;
    position: relative;
    display: grid;
    overflow-x: hidden;
    grid-template-areas: "a a";
    grid-auto-columns: 1fr max-content;
    background: transparent;
    .secondary-menu{
      display: flex;
    }
    &.menu-active{
      display: grid;
      padding-left: 0.65em;
    }
    .main-menu{
      padding-left: 0;
    }
  }
}

.menu .arrows{
  background: url("../img/zdoublearrow-01.svg");
  background-repeat: no-repeat;
  width: 2.5em;
  position: absolute;
  top: 0.1em;
  left: 0.5em;
  height: var(--hh);
  display: block;
  z-index: 99999999999;
  overflow: hidden;
  @include mq(md){
    display: none;
  }
}

.main-menu {
  display: flex;
  white-space: nowrap;
  overflow-x: scroll;
  overflow-y: hidden;
  @include mq(md){
    overflow-x: auto;
    white-space: unset;
  }
}

.secondary-menu {
  display: flex;
  justify-content: flex-end;
  margin-right: 0.65em;
  width: 10em;
  .menu-item:nth-of-type(1){
    &:after{
      content: "¦";
      display: inline-block;
      text-decoration: none !important;
      margin-left: 0.25em;
    }
  }
  .menu-item:last-of-type{
    margin-right: 0em;
  }
  @include mq(md){
    margin-right: 0;
    .menu-item:nth-of-type(1){
      &:after{
        content: none;
        margin-left: 0;
      }
    }
    .menu-item:nth-of-type(2){
      &:after{
        content: "¦";
        text-decoration: none !important;
        display: inline-block;
        margin-left: 0.25em;
      }
    }
  }
}

.menu-item.language {
  text-transform: capitalize;
  margin-right: 0.25em;
  &:hover{
    &:after{
      text-decoration: none !important;
    }
  }
  &:last-of-type{
  }
}

.submenu-container {
  position: fixed;
  top: calc(var(--hh)*2);
  height: calc(var(--vh) - var(--hh)* 2);
  left: 0;
  width: 100%;
  z-index: 9999999999999;
  overflow-y: scroll;
  @include padding(0 0.65 0.4 0.65);
  background: $greypurple;
  @include mq(md){
    height: 100%;
    background: transparent;
  }
  &.hide {
    display: none;
  }
  @include mq(md){
    top: var(--hh);
    height: 100%;
  }
  .submenu{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: min-content;
    &.hide{
      > *{
        display: none;
        overflow: hidden;
        opacity: 0;
      }
    }
  }
  .items{
    grid-column: span 2;
    /*&.col-2{
      grid-column: span 2;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: min-content;
      !*width: max-content;*!
      border-bottom: 1px solid;
      @include mq(md){
      }
    }*/
    .submenu-item{
      margin-bottom: -1px;
    }

  }
}

.menu-item, .submenu-filter {
  margin-right: 0.8em;

  &:hover {
    @extend .underline;
  }

  &.active {
    @extend .underline;
  }
}

.submenu-filters {
  display: flex;
  grid-column: span 2;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  li:last-of-type{
    margin-right: 0;
  }
  @include mq(md){
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
  }

  .submenu-categories{
    display: flex;
  }

  .submenu-dates{
    display: none;
    &.show{
      display: flex;
    }
  }

  .submenu-filter {
    @include padding(0.4 0 0.4 0);
  }
}

.submenu-categories .submenu-filter {
  margin: 0;

  &:after {
    margin-left: 0.4em;
    margin-right: 0.4em;
    content: "|";
  }

  &:last-of-type {
    &:after {
      content: none;
    }
  }
}

.submenu-item {
  grid-column: span 2;
  align-self: start;
  border-bottom: 1px solid;
  @include padding(0.4 0 0.4 0);

  &.hide {
    display: none;
    opacity: 0 !important;
  }

  &:hover {
    @include font(mainita);
    text-shadow: $greypurple 1px 0 10px;
  }
}

.actualites .submenu-item{
  &:hover{
    @include font(main);
  }
}

.submenu-item[data-grid="2"]{
  grid-column: span 1;
}


// MOBILE MENU

#home .header-nav-sm{
  .logo{
    opacity: 0;
    pointer-events: none;
    &.active{
      opacity: 1;
    }
  }
}

.header-nav-sm{
  @include padding(0.4 0.65);
  display: grid;
  grid-template-areas: "a a a";
  grid-auto-columns: 1fr min-content min-content;
  align-items: center;
  background: linear-gradient(90deg, rgba(200,204,220,1) 0%, rgba(255,255,255,1) 4%, rgba(200,204,220,1) 19%, rgba(255,255,255,1) 48%, rgba(200,204,220,1) 66%, rgba(255,255,255,1) 72%, rgba(200,204,220,1) 84%, rgba(255,255,255,1) 100%);
  background-size: 200% 100%;
  animation: AnimationName 3s ease infinite alternate;
  background-position: 100% 51%;
  z-index: 999;
  .logo{
    font-size: 1.2em;
    width: auto;
    text-align: left;
    position: relative;
    z-index: 99999;
    top: unset;
    transform: none;
  }
  .menu-toggle{
    @include font(icons);
    @include fs(icons);
    @extend .icon;
    @extend .icon-burger;
    height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
    &.active{
      @extend .icon-cross;
    }
    &:before{
      height: 100%;
      display: flex;
      align-items: center;
      position: relative;
      top: -0.15em;
    }
  }
  @include mq(md){
    display: none;
  }
}
