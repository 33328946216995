// __________________________________________________________ //

// GALLERY


// POST GALLERY IMAGE
.post-image{position: relative; height: calc(var(--vh) - var(--hh));}
.post-text{ background: $greypurple;}
.post-legend{ position: sticky; top: 0em; z-index: 9; @include padding(0.65 0); border-bottom: 1px solid; @include margin(0 0.65); }


.post-image{
  height: auto;
  @include mq(md){
    height: calc(var(--vh) - var(--hh));
  }
}
.post-image .fullscreen{
  position: initial;
  top:0;
  width: 100%;
  height: auto;
  @include mq(md){
    position: absolute;
    height: 100%;
  }
}

.page-mobile-info{
  display: flex;
  justify-content: space-between;
  background: $purple;
  color: black !important;
  @include padding(0.4 0.65 0.4 0.65);
  top:0;
  z-index: 2;
  position: sticky;
  @include mq(md){
    display: none;
  }
}


// POST GALLERY LEGEND

.post-legend{
  display: none;
  grid-template-areas: "a a";
  grid-auto-columns: 1fr minmax(8em, max-content);
  @include mq(md){
    display: grid;
  }
}
.post-legend-number{
  display: flex;
  justify-content: space-between;
}

.post-legend-text{
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-auto-columns: minmax(min-content, max-content) minmax(min-content, max-content);
  grid-column-gap: 1.6em;
  grid-auto-flow: column;
}

#loaded-post-container {
  .post-legend-number {
    margin-left: 1em;
  }
}


// __________________________________________________________ //

// ARTISTS TEXT INFOS

.top-4{
  @include mq(md){
    position: relative;
    top: -4px
  }
}

.post-text{
  min-height: calc(var(--vh) - var(--hh) * 2);
  @include padding(0 0.65 0);
  @include mq(md){
    height: calc(var(--vh) - var(--hh) + 1px);
  }
}

body[data-type="artistes"]{
  .header-col{
    display: none;
    &.txt-center{
      display: block;
    }
    @include mq(md){
      display: block;
    }
  }

}

.post-text-header{
  display: flex;
  flex-direction: column;
  div:nth-of-type(3){
    order: -1;
  }

  .header-col{
    text-align: left;
    border-top: 1px solid;
    border-bottom: 1px solid;
    margin-top: -1px;
    align-self: end;
    width: 100%;
    @include padding(0.4 0);
    .txt-right{
      text-align: left;
      @include mq(md){
        text-align: right;
      }
    }
  }

  .header-name{
    @include mq(md){
      word-spacing: 9999999px;
    }
    @include padding(1.2 0 1.2 0);
  }

  @include mq(md){
    @include padding(0.6 0 0 0);
    border-bottom: 1px solid;
    display: grid;
    grid-template-areas: "a a a"
                       "b c d";
    grid-template-columns: minmax(max-content,25%) 1fr minmax(max-content, 25%);
    div:first-of-type{
      grid-area: a;
      @include padding(0 0 0.6)
    }
    div:nth-of-type(3){
      order: 0;
    }
    .header-col{
      padding: 0;
      border: none;
      text-align: initial;
      width: auto;
      @include padding(0.6 0 0.4);
      &.txt-center{
        text-align: center;
      }
    }
    .header-name{
      word-spacing: initial;
      @include padding(0.2 0 0 0);
    }
  }
}

