*::-webkit-scrollbar{
  display: none;
}

.ff-title{
  @include font(title);
  @include fs(title);
  text-transform: uppercase;
}

picture{
  img {
    display: block;
    height: 100%;
    min-width: 100%;
    object-fit: cover;
  }
}

html, body{
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  @include mq(md){
    background: $greypurple;
  }
}

#home{
  background: linear-gradient(to bottom, $greypurple, white, $greypurple);
}


main{
  width: 100%;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  height: 100%;
  flex: 1;
  position: relative;
  &.blurred{
    filter: blur(8px);
  }
}

.swiper-container{
  height: 100%;
  width: 100%;
}

.swiper-slide{
  position: relative;
  overflow: auto;
  height: 100%;
  position: relative;
}

.swiper-slide.text-slide{
  background: grey;
}

.swiper-legend{
  padding: 0.5em 0;
  margin: 0 0.5em;
  width: calc(100% - 1em);
  position: absolute;
  border-bottom: 1px solid black;
}



.image-container{
  background-color: rgba(244,244,244,0.6);
  padding: 0 20%;
}

.swiper-slide:nth-of-type(1){
  background: lightgray;
}
.swiper-slide:nth-of-type(2){
  background: lightpink;
}

.swiper-slide:nth-of-type(3){
  background: lightyellow;
}

.swiper-slide:nth-of-type(4){
  background: linen;
}

.txt-right{
  text-align: right;
}
