
@keyframes AnimationName {
  0%   {background-position:100% 51%}
  50%  {background-position:0% 50%}
  100% {background-position:100% 51%}
}


#home header, #about header {
  width: 100%;
  position: relative;
  background: linear-gradient(90deg, rgba(200,204,220,1) 0%, rgba(255,255,255,1) 4%, rgba(200,204,220,1) 19%, rgba(255,255,255,1) 48%, rgba(200,204,220,1) 66%, rgba(255,255,255,1) 72%, rgba(200,204,220,1) 84%, rgba(255,255,255,1) 100%);
  background-size: 200% 100%;
  animation: AnimationName 3s ease infinite alternate;
  background-position: 100% 51%;

  &:before {
    @include mq(md) {
      content: "";
      position: absolute;
      top:0;
      left:0;
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
    }
  }
}

#home .header-nav-sm{
  position: fixed;
  width: 100%;
z-index: 99999999999;
}

#home header .menu{
  z-index: 99999999999;
}

#home .baseline{
  z-index: 99;
}

header{
  height: 40px !important;
  @include mq(md){
    background: $purple;
    z-index: unset;
    height: unset;
  }

}


#home .baseline{
  display: block;
}
.baseline {
  display:none;
  position: fixed;
  bottom:0;
  left: 0;
  padding: 0.65em 0.65em 0.65em;
  width: 100%;
  height: calc(50% - var(--hh) / 2);
  z-index: -1;
  pointer-events: none;
  @include mq(md){
    display: block;
    height: auto;
    z-index: 999;
  }
}

.logo {
  @include font(logo);
  @include fs(logo);
  width: 100%;
  text-align: center;
  pointer-events: initial;
  cursor: pointer;
  line-height: 0.85em !important;
  z-index: -9999;
  position: absolute;
  left:0;
  top: calc(50% + 1rem);
  transform: translateY(-50%);
  @include mq(md){
    position : relative;
    width: min-content;
    left: unset;
    top: unset;
    bottom: 0;
    transform: none;
  }
}

.baseline-link{
  display: block;
  pointer-events: auto;
}

.page-info {
  @include mq(md) {
    position: relative;
    display: grid;
    grid-template-areas: "a a";
    grid-auto-columns: min-content 1fr;
  }
  position: absolute;
  top: 0;
  left:0;
  text-align: left;
  width: 100%;
  display: flex;
  display:none;
  z-index: 9999;
  @include padding( 0.2 0.4 0 0);

  p {
    white-space: nowrap;
  }

  .page-info-date {
    grid-column-start: 2;
  }

  .m-space{
    margin-right: 0.25em;
  }

}

.marquee{
  position: absolute;
  top: 0;
  left:0;
  overflow: hidden;
  display: flex;
  @include padding( 0.4 0.4 0.4 0.4);
  @include mq(md){
    display: none;
  }
  div{
    display: flex;
    padding-right: 1em;
  }
  p{
    white-space: nowrap;
  }
  .page-info-date{
    padding-left: 1em;
  }
}

.default-pattern.pattern-img {
  @include mq(md) {
    width: 11.5em;
    left: 15px
  }
}

.pattern-img {
  width: 80%;
  position: absolute;
  bottom: -10%;
  left: 5%;
  @include mq(md){
    width: 13.5em;
    transform: none;
    bottom:0;
    left: 0;
  }

}

#home .pattern-img{
  display: none;
  @include mq(md){
    display: block;
  }
  &.mobile-pattern{
    display: block;
    @include mq(md){
      display: none;
    }
  }
}

.mobile-pattern{
  display: block;
  left: 11%;
  bottom: -0.5vh;
  width: 78%;
  @include mq(md){
    display: none;
  }
}