.about-row {
  @include padding(0.4 0 0.4 0);
  border-bottom: 1px solid;

  p {
    margin-bottom: 0.2em;
  }
}

.about-text {
  .collapsible{
    display: block;
  }
  .content{
    border-bottom: 1px solid;
  }
  @include mq(md) {
    .collapsible {
      display: none;
    }
    .content {
      border-bottom: 1px solid;
      height: auto;
      min-height: unset !important;
      max-height: unset !important;
    }
  }
}

.about-row.about-info {
  @include padding(0.4 0 0.4 0);
  border-bottom: 1px solid;
  display: flex;
  flex-direction: column;

  .links {
    display: none;
    order: 2;

    a:first-of-type {
      margin-right: 0.8em;
    }
  }

  @include mq(md) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    .links {
      display: block;
      text-align: right;
      order: 0;
    }
    .info {
      margin-bottom: 0;
    }
  }


}

.adress {
  width: min-content;
  word-break: keep-all;
  white-space: nowrap;
  display: grid;
  grid-template-areas: "a a";
  margin-bottom: 1em;
}

.post-text {
  display: flex;
  flex-direction: column;
  @include mq(md) {
    display: block;
  }

  .about-info {
    order: -1;
  }
}

#about .block-type-collapse:last-of-type{
  .content.collapsed{
    max-height: 10vh !important;
  }
}