#post-grid {
  header {
    @include mq(md) {
      background: $purple;
    }
    a{
      color: black;
    }
    .baseline .logo a{
      color: $purple;
    }
    .menu {
      color: black;

      a {
        color: black;
      }
    }

    .baseline {
      color: $purple;
    }
  }

  main {
    color: $purple;
    background: $greypurple;
  }

  .logo {
    color: $purple;
  }

  .page-info{
    color: $purple;
  }

  a {
    color: $purple;
  }

}

.posts-grid {
  display: block;
  grid-template-areas: "a";
  grid-auto-columns: 1fr;
  grid-column-gap: 0.8em;
  grid-row-gap: 1.25em;
  padding: 0.8em;
  .post{
    margin-bottom: 1.25em;
  }
  @include mq(md) {
    display: grid;
    grid-template-areas: "a a a";
    grid-auto-columns: 1fr 1fr 1fr;
    .post{
      margin-bottom: 0;
    }
  }
}

.post-cover {
  height: auto;
  aspect-ratio: 3 / 2;
  cursor: pointer;
}

.post-cover .grayscale img {
  filter: grayscale(100);
  mix-blend-mode: multiply;
}

.post-cover:hover .grayscale img {
  filter: grayscale(0);
  mix-blend-mode: unset;
}

.post-info {
  padding-top: 0.6em;
}

.post-grid-post-info{
  @extend .underline;
  margin-left: 2em;
}



// LOADED POST

#loaded-post-container {
  position: fixed;
  background: $greypurple;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  bottom:0;
  display: none;
  height: calc(100% - var(--hh) * 2);
  @include mq(md){
    height: calc(100% - var(--hh));
  }
  width: 100%;
  z-index: 99;
}

#loaded-post-container .gallery .post-legend{
  display: none;
  @include mq(md){
    display: grid;
    grid-template-areas: "a a a a";
    grid-column-gap: 0.65em;
    grid-auto-columns: max-content 1fr min-content min-content;
  }
}

#loaded-post-container .gallery .post-legend-number{
 display: block;
}

#loaded-post-container .gallery .post-legend-text{
  display: block;
}

.close-cross{
  cursor: pointer;
  @extend .icon;
  @extend .icon-cross;
  position: relative;
  top: -0.2em;
  align-self: start;
}

.back{
  position: sticky;
  top: 0em;
  margin-top: -2.5em;
  align-self: flex-start;
  z-index: 1;
  transform: rotate(90deg);
  font-weight: bolder;
  width: min-content;
  left: 0.65em;
  &:before{
    font-size: 2.5em !important;
  }
  @include mq(md){
    display: none;
  }
}