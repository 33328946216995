@mixin mq($mq-breakpoint, $mq-breakpoints: $breakpoints) {
  @if map-has-key($mq-breakpoints, $mq-breakpoint) {
    $mq-breakpoint: map-get($mq-breakpoints, $mq-breakpoint);
  }
  @media screen and (min-width: #{$mq-breakpoint}) {
    @content;
  }
}

@mixin transition(
  $property: $transition-property-default,
  $duration: $transition-duration-default,
  $timing: $transition-timing-default,
  $delay: $transition-delay-default
) {
  transition-property: $property;
  transition-duration: $duration;
  transition-timing-function: $timing;
  transition-delay: $delay;
}

@mixin fs($size: main) {
  font-size: var(--fs-#{$size});
  line-height: var(--lh-#{$size});
  letter-spacing: var(--ls-#{$size});;
}

@mixin font($font: main) {
  font-family: map-get(map-get($fonts, $font), name), unquote(map-get(map-get($fonts, $font), fallback));
}
