// CONFIGURATE DOCUMENT

$fontsize: (
        main: (
                sm: (
                        fs: 20px,
                        lh: 1.1em,
                        ls: -0.02em,
                ),
                md: (
                        fs: 20px,
                        lh: 1.1em,
                        ls: -0.02em,
                ),
        ),
        mainita: (
                sm: (
                        fs: 20px,
                        lh: 1.1em,
                        ls: -0.02em,
                ),
                md: (
                        fs: 20px,
                        lh: 1.1em,
                        ls: -0.02em,
                ),
        ),
        title: (
                sm: (
                        fs: 63px,
                        lh: 0.85em,
                        ls: normal,
                ),
                md: (
                        fs: 63px,
                        lh: 0.85em,
                        ls: normal,
                ),
        ),
        logo: (
                sm: (
                        fs: 22vw,
                        lh: 0.85em,
                        ls: normal,
                ),
                md: (
                        fs: 63px,
                        lh: 0.85em,
                        ls: normal,
                ),
        ),
        icons: (
                sm: (
                        fs: 1em,
                        lh: 1em,
                        ls: normal,
                ),
                md: (
                        fs: 1em,
                        lh: 1em,
                        ls: normal,
                ),
        ),


);

$margin: (
        sm: 1,
);

:root {
  --base-space: 23px;
  --color-text: black;
  --color-link: black;
  --color-bg: white;
}

// __________________________________________________________ //

// FONTS

$fonts: (
        main: (
                name: zapfmedium,
                fallback: "Zapf-Intl-ITC-Std-Medium, serif",
                withFile: true,
        ),
        mainita: (
                name: zapfmediumita,
                fallback: "Zapf-Intl-ITC-Std-Medium-Italic, serif",
                withFile: true,
        ),
        title: (
                name: quorumed,
                fallback: "Quorum-Medium",
                withFile: true,
        ),
        logo: (
                name: quorumed,
                fallback: "Quorum-Medium",
                withFile: true,
        ),
        icons: (
                name: icons,
                fallback: "Icons",
                withFile: true,
        ),

);

// __________________________________________________________ //

// BREAKPOINTS

$breakpoints: (
        sm: 0px,
        md: 768px,
);

// __________________________________________________________ //

// TRANSITION

$transition-property-default: all;
$transition-duration-default: 0.2s;
$transition-timing-default: cubic-bezier(0.7, 0, 0.3, 1);
$transition-delay-default: 0s;

// __________________________________________________________ //

// COLORS

$purple: #8664ff;
$greypurple: #c8ccdc;
