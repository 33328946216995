// BORDER-BOX, RESET

html {
  box-sizing: border-box;
}

*,
*:after,
*:before {
  box-sizing: inherit;
}

* {
  margin: 0;
  -webkit-font-feature-settings: "kern" 1;
  font-feature-settings: "kern" 1;
  -webkit-font-kerning: normal;
  font-kerning: normal;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

body,
html {
  -webkit-tap-highlight-color: transparent;
  height: 100%;
  margin: 0 !important;
  padding: 0 !important;
}

// __________________________________________________________ //

// TYPE SIZE

html,
body {
  @include font;
  @include fs;
  color: var(--color-text);
  background-color: $greypurple;
}

html {
  background: $greypurple;
}

a {
  color: var(--color-link);
  text-decoration: none;
  cursor: pointer;
}

ul,
ol {
  margin: 0;
  padding: 0;
}

ul {
  list-style-type: none;
}

ol {
  list-style-position: inside;
}

img {
  width: 100%;
}

strong,
b {
  font-weight: normal;
}

i,
em {
  font-style: normal;
  @include font(mainita);
}

// __________________________________________________________ //

// TYPE SETTINGS

.underline-md{
  @include mq(md){
    text-decoration-line: underline;
    text-decoration-thickness: 1px;
    text-underline-offset: 3px;
  }
}

.underline{
  text-decoration-line: underline;
  text-decoration-thickness: 1px;
  text-underline-offset: 3px;
}

.txt-center{
  text-align: center;
}
